import axios from '../axios'
// axios.defaults.timeout = 2500;

const  StatusService = (function(){
    
    let instance;
    function Service(){}
    Service.prototype.ping = function(){
       
        return axios.get(`ping`, {timeout: 5000}).then((res) => {
            return res.data;
        }).catch((err) => {
            return err;
        })
    }

    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
    
})()

export default StatusService
