import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from './store'
import router from "./router"
import axios from "axios"

Vue.prototype.$orange = '#f7941e'
Vue.prototype.$blue = '#002135'
Vue.prototype.$axios = axios

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
