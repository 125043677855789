import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#002135',
        anchor: '#8c9eff',
        secondary: '#f7941e',
        accent: '#f7941e',
        error: '#b71c1c',
        background: '#002135'
      },
      dark: {
        primary: '#002135',
        anchor: '#8c9eff',
        secondary: '#f7941e',
        accent: '#f7941e',
        error: '#b71c1c',
        background: '#002135'
      },
    },
    options: {
      customProperties: true,
    },
  },
});
