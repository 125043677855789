<template>
  <v-app>
      <appheader></appheader>
      <eulogos></eulogos>
      <router-view></router-view>
      <appfooter></appfooter>
      <contactbutton></contactbutton>
      <error></error>
      <signin-form></signin-form>
  </v-app>
</template>

<style>
html * {
   font-family: Arial !important;
}
html {
  background: #002135;
}
.bg {
  min-height: 1400px;
  padding-top: 55px;
}
.global-title {
  color: #f7941e;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
}
.global-title-sm {
  color: #f7941e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.global-blue {
  background: #002135;
}
.text {
  cursor: pointer;
}
</style>

<script>
import Status from './services/status.js'

export default {
  name: "App",
  components: {
    contactbutton: () => import("./components/ContactButton"),
    appheader: () => import("./components/Header"),
    appfooter: () => import("./components/Footer"),
    error: () => import("./components/Error"),
    SigninForm: () => import("./components/Signin"),
    eulogos: () => import("./components/EU_Logos_In_Pages")
  },
  created() {
    this.$store.dispatch("tryAutoLogin");

    Status.getInstance().ping().then(res => {

      if(res.includes('Error')) {
        this.$store.commit("giveErrorMessage", {
            msg: {
              fi: "Palvelua huolletaan parhaillaan, kaikki toiminnot eivät välttämättä ole käytössä. \nPahoittelut häiriöstä.",
              en: "Service is under maintenance, not all functionalities will be available."
            }
          });
      }
      

    }).catch(err => {
      this.$store.commit("giveErrorMessage", {
            msg: {
              fi: "Palvelua huolletaan parhaillaan, kaikki toiminnot eivät välttämättä ole käytössä. \nPahoittelut häiriöstä.",
              en: "Service is under maintenance, not all functionalities will be available."
            }
          });

    })

    // Interval to be changed or checked when the token expires
    /* setInterval(() => {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch("refreshToken");
        // console.log("token refreshed")
      }
    }, 20 * 1000); */
  }
};
</script>
