import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: 'fi',
    authToken: null,
    user: null,
    usecases: [],
    loginStatus: false,
    errorMsg: null,
    signinForm: false
  },
  mutations: {
    authUser(state, userData) {
      state.authToken = userData.token
      state.loginStatus = true
      state.usecases = [...userData.usecases]
    },
    authRefresh(state, userData) {
      state.authToken = userData.token
      state.loginStatus = true
    },
    clearAuthData(state) {
      state.authToken = null
      state.loginStatus = false
      state.usecases = []
    },
    changeLocale(state, locale) {
      state.locale = locale
    },
    giveErrorMessage(state, msg) {
      state.errorMsg = msg
    },
    clearError(state) {
      state.errorMsg = null
    },
    toggleSigninForm(state, value) {
      state.signinForm = value
    }
  },
  actions: {
    refreshToken({ commit, dispatch }) {
      const refreshToken = localStorage.getItem("refreshToken")

      commit('authRefresh', {
        token: refreshToken
      })

      // axios-auth.js adds the refreshtoken to headers using interceptors
      axios.patch(`${process.env.VUE_APP_URL}/api/auth/token`, undefined).then(response => {
        if (response.data.authToken != null) {
          window.localStorage.setItem("authToken", response.data.authToken)
          commit('authRefresh', {
            token: response.data.authToken,
          })
        } else {
          dispatch('logout')
        }
      }).catch(err => {
        dispatch('logout')

      })
    },
    login({ commit }, authData) {
      axios.post('auth/login', {
        password: authData.password,
        email: authData.username
      })
        .then(res => {
          if (!res || res.status !== 200) {
            this.$store.commit('giveErrorMessage', {
              msg: {
                fi: 'Sisäänkirjautuminen epäonnistui annetuilla tiedoilla',
                en: "Login failed with given information"
              },
              err: ''
            })
          }

          window.localStorage.setItem('authToken', res.data.authToken);
          window.localStorage.setItem('refreshToken', res.data.refreshToken);
          window.localStorage.setItem('render', JSON.stringify({ render: res.data.usecases }))

          commit('authUser', {
            token: res.data.authToken,
            usecases: res.data.usecases
          })

          commit('giveErrorMessage', {
            msg: {
              fi: 'Sisäänkirjautuminen onnistui',
              en: 'Login successful'
            },
            err: ''
          })
        })
        .catch(error => {
          commit('giveErrorMessage', {
            msg: {
              fi: 'Sisäänkirjautuminen epäonnistui annetuilla tiedoilla',
              en: 'Login failed with given information'
            },
            err: !error.response ? '' : error.response.status
          })
        })
    },
    tryAutoLogin({ commit }) {

      const authToken = localStorage.getItem('authToken')
      const refreshToken = localStorage.getItem('refreshToken')
      const usecases = JSON.parse(localStorage.getItem('render'))

      if (!refreshToken || !authToken || !usecases) {
        return
      }

      commit('authUser', {
        token: authToken,
        usecases: usecases.render
      })
    },
    logout({ commit }) {
      axios.post('auth/logout').then(response => {
        commit('clearAuthData')
        localStorage.removeItem('authToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('render')
        commit('giveErrorMessage', {
          msg: {
            fi: 'Kirjauduit ulos onnistuneesti',
            en: 'You have been logged out successfully'
          },
          err: ''
        })
      }).catch(err => {
        commit('clearAuthData')
        localStorage.removeItem('authToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('render')
        commit('giveErrorMessage', {
          msg: {
            fi: 'Sinut kirjattiin ulos järjestelmästä',
            en: 'You have been logged out'
          },
          err
        })
      })
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    isAuthenticated(state) {
      return state.authToken !== null
    },
    getToken(state) {
      const token = localStorage.getItem('refreshToken')

      if (state.authToken != null) {
        return state.authToken
      } else if (token != null) {
        return token
      }
      return
    },
    getUsecases(state) {
      const usecases = JSON.parse(localStorage.getItem('render'))

      if (state.usecases.length > 0) {
        return state.usecases
      } else if (usecases.render != null) {
        return usecases.render
      } else {
        return []
      }
    }
  }
})

