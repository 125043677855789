import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routes = [
    {
        path: "/industry",
        name: "industry",
        component: () => import("./portal/components/Industry.vue")
    },
    {
        path: "/go-digital",
        name: "go-digital",
        component: () => import("./portal/components/GoDigital.vue")
    },
    {
        path: "/cluster",
        name: "cluster",
        component: () => import("./portal/components/Cluster.vue")
    },
    {
        path: "/themes",
        name: "themes",
        component: () => import("./portal/components/Themes.vue")
    },
    {
        path: "/info",
        name: "info",
        component: () => import("./portal/components/Info.vue")
    },
    {
        path: "/environments",
        name: "environments",
        component: () => import("./portal/components/Environments.vue"),
    },
    {
        path: "/environments/:id",
        name: "environmentView",
        component: () => import("./portal/components/EnvironmentView.vue")
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("./portal/components/Contacts.vue")
    },
    {
        path: "/references",
        name: "references",
        component: () => import("./portal/components/References.vue")
    },
    {
        path: "/tools",
        name: "tools",
        component: () => import("./portal/components/Tools.vue")
    },
    {
        path: "/",
        name: "portal",
        component: () => import("./portal/components/Portal.vue")
    },
    {
        path: '/login', name: "login",
        beforeEnter(to, from, next) {
            store.commit('toggleSigninForm', true)
        }
    },
    {
        path: "/logout",
        name: "logout",
        beforeEnter(to, from, next) {
            store.dispatch('logout')
        }
    }
]

 
  

export default new VueRouter({ mode: 'hash', routes, scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }})