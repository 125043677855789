import axios from 'axios'
import store from './store.js'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/`
})


instance.interceptors.response.use(res => {
  return res
}, async (err) => {

    if (err?.response?.data?.err == "TokenExpiredError") {
      await store.dispatch("refreshToken")
    }
    // TODO: Fix this!
    return Promise.reject(err)

})

instance.interceptors.request.use(async (config) => {
  try {


    if (store.getters.getToken) {

      if(!config.url.includes('/api/auth/token')){

        let tokenValidUntil = JSON.parse(atob(store.getters.getToken.split('.')[1]))['exp']
        let comparableDateTimeNow = ~~(Date.now() / 1000) 
        
        if ((tokenValidUntil) <= comparableDateTimeNow) {
          await store.dispatch("refreshToken")
        }
        
      }
      config.headers = {
        'authorization': `Bearer ${store.getters.getToken}`
      }
    }
    return config
  } catch (e) {
    // console.log("Error in axios auth", e)
    return Promise.reject(e)
  }
}, (err) => {
  return Promise.reject(err)
})

export default instance